.boldTitle {
  font-weight: bold;
}
.bulletItem {
  max-width: 750px;
  margin: auto;
  margin-bottom: 3px;
}
.privacyParagraph {
  max-width: 750px;
  margin: auto;
  margin-bottom: 10px;
}
.privacyPolicyDiv {
  padding: 20px;
}
