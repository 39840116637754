.appButton {
  box-shadow: 0 10px 25px rgb(123 104 238 / 50%);
  cursor: pointer;
}
.homeImage {
  box-shadow: 5px 10px 5px rgb(123 104 238 / 30%);
}
.rightColumn {
  display: flex;
  align-items: center;
  /* background-color: yellow; */
}

.titley {
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  /* font-family: Axiforma; */
  /* font: 800 39px/53px Axiforma, Arial, serif; */
  color: #292d34;
  font-weight: bold;
  /* padding-left: 10px;
  padding-right: 10px; */
  /* max-width: 500px; */
}
